@each $company, $logo in map-get($companies, logo) {
  .header.company-#{$company} {
    .header {
      &__logo {
        top: map-deep($logo, top);
        min-width: map-get($logo, width);

        img {
          width: map-get($logo, width);
          height: map-get($logo, height);
        }

        @include media('>=medium') {
          top: map-deep($logo, 'breakpointMedium', 'top');
          min-width: map-deep($logo, 'breakpointMedium', 'width');

          img {
            width: map-deep($logo, 'breakpointMedium', 'width');
            height: map-deep($logo, 'breakpointMedium', 'height');
          }
        }

        @include media('>=large') {
          top: map-deep($logo, 'breakpointLarge', 'top');
          width: map-deep($logo, 'breakpointLarge', 'width');
          height: map-deep($logo, 'breakpointLarge', 'height');

          img {
            width: map-deep($logo, 'breakpointLarge', 'width');
            height: map-deep($logo, 'breakpointLarge', 'height');
          }
        }
      }
    }

    .menu--third {
      @include media('>=large') {
        padding-left: map-get($logo, width) + $padding-small;
      }
    }
  }

  .footer.company-#{$company} {
    .footer-branding__item:first-child {
      width: map-get($logo, width);
      height: map-get($logo, height);
    }
  }
}

.footer.company-mestre {
  .footer-branding__item:first-child {
    width: 113px;
    height: 26px;
  }
}

.header.company-cdf {
  .header {
    &__logo {
      @include media('<small') {
        top: 21px;
        left: 45px;
        width: 150px;
        height: 27px;
      }

      img {
        @include media('<small') {
          width: 150px;
          height: 27px;
        }
      }
    }
  }

  .menu--second {
    @include media('>=large') {
      max-width: 35%;
      flex-basis: 35%;
    }
  }

  .menu--third {
    @include media('>=large') {
      max-width: 65%;
      flex-basis: 65%;
      padding-left: 220px;
    }

    .menu__link {
      @include media('>=large') {
        white-space: nowrap;
      }
    }
  }
}

.header.company-ps {
  .header {
    &__logo {
      @include media('<small') {
        top: 22px;
        left: 45px;
        width: 147px;
        height: 30px;
      }

      img {
        @include media('<small') {
          width: 147px;
          height: 30px;
        }
      }
    }
  }

  .menu--third {
    @include media('>=large') {
      padding-left: 187px;
    }
  }
}

.company-ps {
  .page--rdv {
    .section {
      &--text {
        margin-bottom: 60px;
      }

      &--2-blocs-hv {
        @include media('>=medium') {
          margin-top: 50px;
        }

        .contents--inner-v {
          .item__wrapper {
            @include media('>=large') {
              max-width: calc(100% - 40px);
            }
          }
        }

        .item {
          &__title {
            margin-bottom: 0;
          }

          &__link {
            color: map-get($gray, medium);
            font: {
              size: rem(18px);
              weight: 500;
            }
          }

          &__text {
            margin-top: 0;
            font: {
              size: rem(18px);
              weight: 500;
            }
          }
        }
      }
    }
  }
}
// Pompac
.company-po {
    .header{
        @include media('<large') {
            &__links {
                background: map-get($blue, dark);
                position: relative;
                display: block;
                margin-bottom: 10px;

                .icon-links {
                    &__list {
                        position: relative;
                        z-index: 10;
                    }
                    &::after {
                        content: '';
                        position: absolute;
                        width: 101vw;
                        top: -20px;
                        bottom: 0;
                        background: map-get($blue, dark);
                        transform: translateX(-50%);
                        left: 50%;
                        z-index: 0;
                    }
                    &__title {
                        color: #ffffff;
                    }
                    &__svg {
                        .svg-icon {
                            fill: #ffffff;
                        }
                    }
                }
            }
        }
        @include media('>=large') {
            &__quick-menus {
                background: map-get($blue, dark);
                position: relative;

                &::before {
                    content: '';
                    position: absolute;
                    width: 101vw;
                    top: 0;
                    bottom: 0;
                    background: map-get($blue, dark);
                    transform: translateX(-50%);
                    left: 50%;
                }

                .menu__link {
                    color: #ffffff;

                    &:hover {
                        color: map-get($blue, light);
                    }
                }
            }
        }
    }
}


// Guiraud
.company-guiraud {
  .header {
    .menu__item--find-prod,
    .icon-links__item--find-pro {
      display: none;
      pointer-events: none;
    }
  }

  .page--homepage-services {
    @include media('>=large') {
      width: 50%;
    }

    .section {
      &__header {
        margin: {
          right: auto;
          left: auto;
        };
        text-align: center;
      }

      &__title {
        width: 100%;
        margin: {
          right: auto;
          left: auto;
        };
        text-align: center;
      }

      &__text {
        width: 100%;
        margin: {
          right: auto;
          left: auto;
        };
        text-align: center;
      }
    }

    .item {
      width: 100%;
      margin: {
        right: auto;
        left: auto;
      };
    }
  }

  .section--networks {
    .wrapper--small {
      width: 100%;

      @include media('>=large') {
        width: 50%;
      }
    }

    .item {
      width: 100%;
      margin: {
        right: auto;
        left: auto;
      }
    }
  }

  .footer-nav {
    @include media('>=large') {
      margin-right: 200px;
    }
  }
}
